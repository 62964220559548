const speaking = [
  {
    title: 'JS Chicago - An Introduction to React',
    path:
      'https://www.meetup.com/js-chi/events/258154685/',
  },
  {
    title: 'WordCamp Chicago - Developing a WordPress Theme from Scratch',
    path:
      'https://wordpress.tv/2017/08/30/tania-rascia-how-to-develop-a-wordpress-theme-from-scratch/',
  },
]

export default speaking
